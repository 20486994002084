export default {
	main_page: {
		title_full: "Арзамас - Дивеево - Саров",
		content_blocks: [
			{
				body:
					"«Серафимова Земля» объединяет в себе более 100 архитектурных ансамблей монастырей, уникальных соборов, храмов и церквей.",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	radio_page: {
		title_full: "Православные радиостанции",
		content_blocks: [
			{
				body:
					"В эфире данных радиостанций вы услышите евангельские проповеди, беседы со служителями церкви, молитвы, рассказы о любви, славных страницах истории, о детях и самых обычных жизненных радостях и многое, что несет надежду и смысл жизни.",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/12/03/RadioPage_00ecb53f-8a05-471b-9f43-888648b7a0bc.jpg",
		},
	},
	virtual_tours_page: {
		title_full: "Виртуальные экскурсии",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	museums_page: {
		title_full: "Музеи",
		content_blocks: [
			{
				body:
					"Музеи",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	store_page: {
		title_full: "Интернет-магазин",
		content_blocks: [
			{
				body:
					"В интернет-магазине будут представлены уникальные изделия ручной работы мастеров Нижегородской области, а также фирменная продукция монастырей Нижегородской епархии.",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	serafim_project_page: {
		title_full: "Серафимовские проекты",
		content_blocks: [
			{
				body:
					"Начиная с 2005 года, благотворительный фонд преподобного Серафима Саровского при поддержке аппарата полномочного представителя Президента РФ в Приволжском федеральном округе проводит международный педагогический конкурс «Серафимовский учитель».",
			},
		],
		main_image: {
			src: "https://storage.yandexcloud.net/serafim-uploads/content/2020/12/03/serafim-project_635fe1ce-ef00-4ba6-9d4e-d4cf4b367848.png",
		},
	},
	trebs_page: {
		title_full: "Подать записку",
		content_blocks: [
			{
				body:
					"Дорогие братия и сестры! Здесь Вы можете подать записки о здравии и об упокоении близких в монастырях Арзамаса и Сарова. В записках указываются только имена крещеных в Православной церкви. Имена некрещеных и самоубийц в записках не пишутся. Ни одна Ваша просьба о поминовении не останется незамеченной. Все средства от пожертвований направляются напрямую монастырям.",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	calendar_page: {
		title_full: "Православный календарь",
		content_blocks: [
			{
				body:
					"Православный церковный христианский календарь на каждый день. Праздники, даты памятных событий, постов и поминальных дней.",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	how_to_help_page: {
		title_full: "Внести вклад",
		content_blocks: [
			{
				body:
					"Просим откликнуться всех неравнодушных и внести свой посильный вклад в дело благоукрашения, реставрации и строительства объектов монастыря! Благодаря вашей помощи могут быть воссозданы храмы, реконструированы монастырские трапезные, келейные корпуса и гостиницы, а также построены уникальные объекты архитектуры.",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	orders_page: {
		title_full: "Мои пожертвования",
		content_blocks: [
			{
				body: "",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	videos_page: {
		title_full: "Видеотека",
		content_blocks: [
			{
				body: "Православные фильмы, передачи, беседы на христианские темы.",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	media_page: {
		title_full: "Медиатека",
		content_blocks: [
			{
				body: "Православные фильмы, фотоальбомы, радио, книги",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	edia_page: {
		title_full: "Магазин",
		content_blocks: [
			{
				body: "Православный интернет магазин",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	broadcasts: {
		title_full: "Онлайн трансляция",
		content_blocks: [
			{
				body: "Православная онлайн трансляция",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	city_objects__page: {
		title_full: "Достопримечательности",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	all_articles: {
		title_full: "Все статьи",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	all_news: {
		title_full: "Все новости",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	all_shrines: {
		title_full: "Все святыни",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	page404: {
		title_full: "",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	holy_places: {
		title_full: "Святые места",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/03/24/170393eb-3de0-4b23-aeb0-577ea11235d1.jpg",
		},
	},
	history_page: {
		title_full: "Исторические блоки",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/2020/06/11/content1_f4ff35f3-43d3-471f-8478-4ce9bdd5fd41.png",
		},
	},
	projects_news_page: {
		title_full: "Все новости проектов",
		content_blocks: [
			{
				body:
					"Все актуальные новости серафимовских проектов",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	map_page: {
		title_full: "Карта жизненного пути Серафима Саровского",
		content_blocks: [
			{
				body:
					"Интерактивная карта жизненного пути Серафима Саровского",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	photoalbums_page: {
		title_full: "Все фотоальбомы",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	profile_page: {
		title_full: "Личный кабинет",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
	tv_obraz_page: {
		title_full: "ТВ образ",
		content_blocks: [
			{
				body:
					"",
			},
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	},
};
