import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Button, message } from "antd";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { removePreloader, makeUrl, removeEmptyKeys } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { useThemeContext } from "../../components/Layout/ThemeContext";

import { ALL_PROJECTS_MATERIALS, ALL_PROJECTS_MATERIALS_UPDATED_AT } from "../../queries/queries.graphql";
import { ContentList } from "../../components/Lists";
import seoData from "../../components/SEO/data";

export const query = graphql`
	query projectsMaterials($pagePath: String) {
		hasura {
			...SerafimProjectsMaterials
			...PageSettings
		}
	}
`;

const MATERIAL_SLUG_BY_TYPE = {
	news: makeUrl.news,
	media_article: makeUrl.mediaArticle,
};

export default function AllProjectsMaterialsPage({ data }) {
	const { theme } = useThemeContext();

	const url = "all-projects-materials";
	const limit = 6;

	const [materials, setMaterials] = useState(get(data, "hasura.v_serafim_projects_materials", []));
	const [loading, setLoading] = useState(true);
	const [offset, setOffset] = useState(limit);
	const [disabled, setDisabled] = useState(false);
	const seo = get(data, "hasura.page_settings[0]", {});

	const maxUpdated = new Date(get(data, "hasura.v_serafim_projects_updated_at[0].updated_at", new Date()));

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } =
		useQuery(ALL_PROJECTS_MATERIALS_UPDATED_AT, { fetchPolicy: "no-cache" });

	const [
		loadMediaVideosFirstChunk,
		{
			called: materialsCalledFirstChunk,
			loading: materialsLoadingFirstChunk,
			error: materialsErrorFirstChunk,
			data: materialsDataFirstChunk,
		},
	] = useLazyQuery(
		ALL_PROJECTS_MATERIALS,
		{
			variables: {
				offset: 0,
				limit,
			},
			fetchPolicy: "no-cache",
		},
	);

	const [
		loadMediaVideos,
		{
			called: materialsCalled,
			loading: materialsLoading,
			error: materialsError,
			data: materialsData,
		},
	] = useLazyQuery(
		ALL_PROJECTS_MATERIALS,
		{
			variables: {
				offset,
				limit,
			},
			fetchPolicy: "no-cache",
		},
	);

	function handleLoad() {
		setLoading(true);

		loadMediaVideos({
			variables: {
				limit,
				offset,
			},
		});
	}

	useEffect(() => {
		if (maxUpdateData && !maxUpdateError) {
			const currentMaxUpdated = new Date(get(maxUpdateData, "v_serafim_projects_updated_at[0].updated_at", new Date()));

			if (+currentMaxUpdated !== +maxUpdated) {
				loadMediaVideosFirstChunk();
				setMaterials([]);
			} else {
				removePreloader();
				setOffset(limit);
			}
		} else if (maxUpdateError) {
			console.error("Invalid load loadSerafimProjectMaterialsMaxUpdate", { maxUpdateError });
			removePreloader();
		}
		setLoading(false);

		removePreloader(!maxUpdateLoading || maxUpdateError);
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (materialsCalledFirstChunk && materialsDataFirstChunk && !materialsErrorFirstChunk) {
			const data = get(materialsDataFirstChunk, "v_serafim_projects_materials", []);

			setMaterials(data);
		} else if (materialsErrorFirstChunk) {
			console.error("Invalid load serafimProjectMaterials", { materialsErrorFirstChunk });
		}
	}, [materialsLoadingFirstChunk]);

	useEffect(() => {
		if (materialsCalled && materialsData && !materialsError) {
			const data = get(materialsData, "v_serafim_projects_materials", []);

			setDisabled(!data.length || data.length !== limit);

			setMaterials([...materials, ...data]);

			setLoading(false);

			setOffset(offset + limit);
		} else if (materialsError) {
			console.error("Invalid load serafimProjectMaterials", { materialsError });
			message.error("Не удалось загрузить материалы");
		}
	}, [materialsLoading]);

	const uniqueMaterials = materials.reduce((result, item) => {
		const title = get(item, "page_title_full", get(item, "page_title_short:", ""));
		const image = get(item, "main_image.src", get(item, "main_image_mobile.src", ""));
		const slug = MATERIAL_SLUG_BY_TYPE[item.type](item);

		if (!result.some(res => res.id === item.id)) {
			result.push({
				title,
				image,
				slug,
			});
		}

		return result;
	}, []);

	return (
		<Pages url={url} entity={seo || get(seoData, "projects_materials_page", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={"Все материалы"}
						pageContext={
							{
								item: {
									title_full: "Гуманитарные проекты",
									slug: "serafim-project",
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock mount={uniqueMaterials && uniqueMaterials.length} key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Все материалы"} className={"pb-0"} />
					<ContentList
						items={uniqueMaterials}
						className={"col-12 col-md-4"}
						style={{
							width: "100%",
							height: "350px",
							objectFit: "cover",
						}}
					/>
					<div className="row justify-content-center mb-5">
						<Button
							disabled={loading || disabled}
							onClick={handleLoad}
							className={"d-noscript-none"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							Посмотреть ещё
						</Button>
					</div>
				</div>
			</ContentBlock>
			<Share url={url} pageTitleShort={"Все материалы"} />
		</Pages>
	);
}

AllProjectsMaterialsPage.propTypes = {
	data: PropTypes.object,
};

AllProjectsMaterialsPage.defaultProps = {
	data: {},
};
